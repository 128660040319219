<template>
  <div class="shops">
    <div class="page-title">
      <span>企业门店</span>
      <el-row class="create" :gutter="8" type="flex" justify="center">
        <el-col :span="16">
          <el-input prefix-icon="el-icon-search" v-model="pagination.name" @change="loadShop"
                    clearable
                    style="max-width: 224px; margin-right: 8px"
                    size="small" placeholder="请输入门店名称"></el-input>
          <el-button @click="loadShop" size="small" type="primary" plain>检索</el-button>
        </el-col>
        <el-col :span="8" style="text-align: right" v-if="$btnCheck('mkt_shop_info')">
          <el-button @click="showVisible" type="primary" size="small"><i class="el-icon-circle-plus-outline"/> 新增门店
          </el-button>
        </el-col>
      </el-row>

    </div>

    <div class="shops-list">
      <div class="shops-item" v-for="(shop, idx) in shops" :key="idx" @click="handleDetail(shop)">
        <div class="shops-item-title">
          <div class="name"><span class="label">门店名称:</span> <span class="value">{{shop.name}}</span></div>
          <div class="address">
            <span class="label">地理位置:</span>
            <span class="info">{{shop.province}}/{{shop.city}}/{{shop.address}}</span>
          </div>

          <div><span class="label">负责人: {{shop.dutyName}}</span></div>
          <div><span class="label">联络人: {{shop.contactName}}</span></div>
          <div><span class="label">电话: {{shop.contactPhone}}</span></div>
        </div>

        <el-row type="flex" justify="space-around" align="middle">
          <el-col :span="5" class="item">
            <p class="item-value">{{shop.status | filterStatus}}</p>
            <p class="item-text margin-l">物料状况</p>
          </el-col>
          <el-col :span="4" class="item">
            <p class="item-value">{{shop.orders | I1000}}</p>
            <p class="item-text">本月订单数</p>
          </el-col>
          <el-col :span="4" class="item">
            <p class="item-value">{{(shop.money / 100) | F1000}}</p>
            <p class="item-text">本月交易额<span class="unit">(元)</span></p>
          </el-col>
          <el-col :span="4" class="item">
            <p class="item-value">{{(shop.netProfit / 100) | F1000}}</p>
            <p class="item-text">本月净盈利<span class="unit">(元)</span></p>
          </el-col>
          <el-col :span="5" class="item">
            <p class="item-value">{{shop.devices}} / {{shop.onlineDevices}}</p>
            <p class="item-text">设备总数/在线数</p>
          </el-col>
          <el-col :span="2" class="item next">
            <i class="el-icon-arrow-right"></i>
          </el-col>
        </el-row>
      </div>

      <div v-if="shops.length === 0" class="shops-img">
        <img src="../../assets/img/no-data.png"/>
        <p>暂无门店信息</p>
      </div>
    </div>
    <el-pagination class="shops-pagination" size="small" @current-change="handleCurrentChange"
                   :current-page="pagination.curPage"
                   :page-size="pagination.limit"
                   layout="total, prev, pager, next"
                   :total="pagination.total"/>
  </div>
</template>

<script>
  export default {
    name: 'shops',
    components: {},
    mounted: function () {
      this.loadShop()
    },
    data: function () {
      return {
        pagination: {
          name: '',
          curPage: 0,
          total: 0,
          start: 0,
          limit: 8,
          startAt: 0,
          endAt: 0,
        },
        addShopVisible: false,
        shops: []
      }
    },
    filters: {
      filterStatus(status) {
        switch (status) {
          case 1:
            return '备料充足'
          case 2:
            return '备料良好'
          case 3:
            return '备料不足'
          case 4:
            return '备料殆尽'
          default:
            return '暂无备料'
        }
      }
    },
    methods: {
      loadShop() {
        let vue = this
        this.$api('mkt.shop.union',this.pagination).then(res => {
          vue.shops = res.list || []
          vue.pagination.total = parseInt(res.total)
        })
      },

      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        this.$api('mkt.shop.list',this.pagination).then(res => {
          this.shops = res || []
        })
      },
      handleDetail(shops) {
        this.$router.push({name: 'mkt.shop.entity', params: {uuid: shops.uuid}})
      },
      showVisible() {
        this.$router.push({name: 'mkt.shop.create'})

      }
    },
  }
</script>

<style lang="scss" scoped>
  .shops {
    @include container();

    &-img{
      text-align: center;
      padding-top: 48px;

    }

    .create {
      float: right;
      width: 416px;
    }

    .create:after {
      @include clearfix();
    }

    &-list {
      min-height: 560px;
      margin-top: $middle-space;

      .shops-item {
        min-height: 152px;
        border: 1px solid $content-border-color;
        border-radius: 4px;
        padding: $middle-space;
        cursor: pointer;
        margin-bottom: $middle-space;

        &-title {
          display: flex;
          justify-items: center;
          align-items: center;

          div {
            min-width: 120px;
            text-align: center;
          }

          .icon {
            color: $brand-warning;
            margin-right: $small-space;
          }

          .address {
            flex: 1;
            text-align: left;

            .info {
              @include font-little();
            }
          }

          .name {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 264px;
          }

          .label {
            @include font-little-h();
            display: inline-block;
            margin-right: $small-space;
          }

          .value {
            color: $color-black;
          }
        }

        .next {
          text-align: center;
        }


        .item {
          margin-top: $middle-space;
          padding-left: $middle-space;
          text-align: center;

          &-text {
            .unit {
              @include font-little-h();
            }
          }

          .margin-l {
            margin-left: $small-space;
          }

          &-value {
            @include font-large-s();
            margin: $small-space 0;
          }
        }
      }

      .shops-item:hover {
        border-color: $theme-color;
        background-color: #fffaf7;

        .next {
          color: $theme-color;
        }
      }
    }

    &-pagination {
      text-align: right;
      margin-bottom: $middle-space;
    }


  }
</style>
